import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Profile",
  setup() {
    const route = useRoute();
    const store = useStore();
    const profile = ref({
      bio: [],
      title: null,
      photo: null,
      name: null,
    });
    const updatePageTitle = (name) => {
      document.title = name;
    };

    onMounted(() => {
      if (route.name == "Governor") {
        store.dispatch("getGovernor").then(() => {
          const governor = store.state.governorsModule.governor;
          profile.value.title = "The Governor, Nasarawa State";
          profile.value.name = governor.name;
          profile.value.photo = governor.photo;
          profile.value.bio = governor.profile;
          updatePageTitle(`${profile.value.name} | ${profile.value.title}`);
        });
      } else if (route.name == "Managing Director & Chief Executive Officer") {
        store.dispatch("getMDCEO").then(() => {
          const mdCEO = store.state.managementTeamModule.mdCEO;
          profile.value.title = "Managing Director & Chief Executive Officer";
          profile.value.name = mdCEO.name;
          profile.value.photo = mdCEO.photo;
          profile.value.bio = mdCEO.profile;
          updatePageTitle(`${profile.value.name} | ${profile.value.title}`);
        });
      }
    });

    return { designation: route.name, profile };
  },
});
