<template>
  <div id="profile">
    <div class="heading text-6xl font-semibold text-center">
      <span v-if="designation == 'Governor'">His Excellency<br /></span>
      {{profile.name}}
    </div>
    <div class="text-3xl text-center my-10">{{profile.title}}</div>
    <div class="profile_body grid grid-cols-2">
      <div class="img">
        <img v-if="profile.photo" :src="profile.photo" />
      </div>
      <div class="text-xl desc text-justify">
        <p
          v-for="paragraph in profile.bio"
          :key="paragraph"
        >
          {{paragraph}}
        </p>
      </div>
    </div>
  </div>
</template>

<script src="./profile.page.js"></script>
<style src="./profile.page.css" scoped lang="css"></style>
